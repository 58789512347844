var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_vm._l((_vm.componentData),function({
      id,
      header,
      sectionName,
      infoIcon,
      infoMessage,
      criteria,
      bodyMessage,
      downloadLink,
      downloadLabel,
    }){return _c('section',{key:id},[(
        sectionName === 'classroomQuality' &&
        _vm.getDataPoint(sectionName) !== null
      )?_c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col pl-0"},[_c('h2',{staticClass:"doe-dark-blue"},[_vm._v(" "+_vm._s(header)+" "),(infoIcon)?_c('base-info-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle.sidebar-Info",modifiers:{"sidebar-Info":true}}],staticClass:"ml-2",on:{"info-click":function($event){return _vm.showModal(_vm.BaseModalType.Message, header, infoMessage)}}}):_vm._e()],1)])]),_c('div',{staticClass:"row"},[_vm._v("   "),_c('div',{staticClass:"col pl-0"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col pl-0"},[_c('h6',{staticClass:"sectionRating"},[_vm._v(_vm._s(_vm.getRating(sectionName)))])])]),(_vm.getScore(sectionName) !== '')?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col pl-0"},[_c('p',[_c('span',{staticClass:"sectionScore"},[_vm._v(_vm._s(_vm.getScore(sectionName)))]),_c('span',{staticClass:"sectionScoreYear"},[_vm._v(" "+_vm._s(_vm.getScoreYear(sectionName))+" ")])])])]):_vm._e(),(_vm.excellenceHonorRoll && _vm.excellenceHonorRoll == 'Yes')?_c('div',{staticClass:"row pl-0 d-flex justify-content-start align-items-center"},[_c('i',{staticClass:"fa fa-check py-2",attrs:{"arial-hidden":"true"}}),_c('span',{staticClass:"px-2"},[_vm._v("Excellence Honor Roll ")]),_c('base-info-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle.sidebar-Info",modifiers:{"sidebar-Info":true}}],staticClass:"ml-2",on:{"info-click":function($event){return _vm.showModal(
                  _vm.BaseModalType.Message,
                  _vm.sidebarMessage.excellanceHonorRollHeader,
                  _vm.sidebarMessage.excellanceHonorRollMessage
                )}}})],1):_vm._e(),(_vm.topGainsHonorRoll && _vm.topGainsHonorRoll == 'Yes')?_c('div',{staticClass:"row pl-0 d-flex justify-content-start align-items-center"},[_c('i',{staticClass:"fa fa-check py-2",attrs:{"arial-hidden":"true"}}),_c('span',{staticClass:"px-3"},[_vm._v("Top Gains Honor Roll ")]),_c('base-info-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle.sidebar-Info",modifiers:{"sidebar-Info":true}}],staticClass:"ml-2",on:{"info-click":function($event){return _vm.showModal(
                  _vm.BaseModalType.Message,
                  _vm.sidebarMessage.topGainsHonorRollHeader,
                  _vm.sidebarMessage.topGainsHonorRollMessage
                )}}})],1):_vm._e(),(downloadLink !== '')?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col pl-0 py-2"},[_c('a',{staticClass:"doe-links-darker",attrs:{"href":downloadLink,"target":"_new","rel":"noopener"}},[_vm._v(_vm._s(downloadLabel))])])]):_vm._e()])])]):_vm._e(),(
        sectionName === 'classroomQualityMeasured' &&
        _vm.getDataPoint(sectionName) !== null
      )?_c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col pl-0"},[_c('h2',{staticClass:"doe-dark-blue"},[_vm._v(" "+_vm._s(header)+" "),(infoIcon)?_c('base-info-button',{staticClass:"ml-2",on:{"info-click":function($event){return _vm.showModal(_vm.BaseModalType.Message, header, infoMessage)}}}):_vm._e()],1)])]),_c('div',{staticClass:"row"},[_vm._v(" ")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col pl-0"},[_c('h6',{staticClass:"sectionRating"},[_vm._v(_vm._s(_vm.getRating(sectionName)))])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col pl-0"},[_c('p',[_c('span',{staticClass:"sectionScore"},[_vm._v(_vm._s(_vm.getScore(sectionName)))]),_c('span',{staticClass:"sectionScoreYear"},[_vm._v(" "+_vm._s(_vm.getScoreYear(sectionName))+" ")])])])]),(criteria && criteria.length > 0)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6 pl-0"},_vm._l((criteria),function({ id, key, value }){return _c('div',{key:id,staticClass:"row"},[_c('div',{staticClass:"col-8 text-left pl-0"},[_c('span',{staticClass:"text-left li"},[_vm._v(_vm._s(key))])]),_c('div',{staticClass:"col-4 text-right pl-0"},[_c('span',{staticClass:"text-right li"},[_vm._v(_vm._s(value))])]),_c('hr',{staticClass:"minor mt-2"})])}),0),_c('div',{staticClass:"col-md-6 pl-0"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col p-0"},[_vm._v(" "+_vm._s(bodyMessage)+" ")])]),_vm._m(0,true),_c('div',{staticClass:"d-flex pb-3"})])]):_vm._e()]):_vm._e(),(
        sectionName === 'networkScore' && _vm.getDataPoint(sectionName) !== null
      )?_c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col pl-0"},[_c('h2',{staticClass:"doe-dark-blue"},[_vm._v(" "+_vm._s(header)),(infoIcon)?_c('base-info-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle.sidebar-Info",modifiers:{"sidebar-Info":true}}],staticClass:"ml-2",on:{"info-click":function($event){return _vm.showModal(_vm.BaseModalType.Message, header, infoMessage)}}}):_vm._e()],1)])]),_c('div',{staticClass:"row"},[_vm._v(" ")]),_c('div',{staticClass:"row"},_vm._l((_vm.getDataPointPerformances(
            sectionName
          )),function({ id, rating, score, scoreYear }){return _c('div',{key:id,staticClass:"col-md-6 pl-0"},[_c('h6',{staticClass:"sectionRating"},[_vm._v(_vm._s(rating))]),(score !== '')?_c('p',[_c('span',{staticClass:"sectionScore"},[_vm._v(" "+_vm._s(score))]),_c('span',{staticClass:"sectionScoreYear"},[_vm._v(" "+_vm._s(scoreYear)+" ")])]):_vm._e(),(id === 1 && _vm.earlyEdDistrictReport != '')?_c('span',{staticClass:"row"},[_c('a',{staticClass:"doe-links-darker",attrs:{"href":_vm.earlyEdDistrictReport,"target":"_new","rel":"noopener"}},[_vm._v(_vm._s(downloadLabel))])]):_vm._e()])}),0)]):_vm._e(),(
        (sectionName === 'preKClassroom' ||
          sectionName === 'toddlerClassroom' ||
          sectionName === 'infantClassroom') &&
        _vm.getDataPoint(sectionName)
      )?_c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col pl-0"},[_c('h2',{staticClass:"doe-dark-blue"},[_vm._v(" "+_vm._s(header)),(infoIcon)?_c('base-info-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle.sidebar-Info",modifiers:{"sidebar-Info":true}}],staticClass:"ml-2",on:{"info-click":function($event){return _vm.showModal(_vm.BaseModalType.Message, header, infoMessage)}}}):_vm._e()],1)])]),_c('div',{staticClass:"row"},[_vm._v(" ")]),_c('div',{staticClass:"row"},_vm._l((criteria),function({
            id,
            type,
            message,
            cardHeader,
            cardInfoIcon,
            cardInfoMessage,
          }){return _c('div',{key:id,staticClass:"col-lg-6 pl-0"},[(type === 'message')?_c('span',{staticClass:"row"},[_c('span',{staticClass:"doe-dark-blue",domProps:{"innerHTML":_vm._s(message)}}),(cardInfoIcon)?_c('base-info-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle.sidebar-Info",modifiers:{"sidebar-Info":true}}],staticClass:"ml-2",on:{"info-click":function($event){return _vm.showModal(_vm.BaseModalType.Message, message, cardInfoMessage)}}}):_vm._e()],1):_c('div',{},[_c('div',{staticClass:"card card-section px-0 pb-0 shadow-none"},[_c('h5',{staticClass:"doe-dark-blue pl-3"},[_vm._v(" "+_vm._s(cardHeader)+" "),(cardInfoIcon)?_c('base-info-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle.sidebar-Info",modifiers:{"sidebar-Info":true}}],staticClass:"ml-2",on:{"info-click":function($event){return _vm.showModal(
                      _vm.BaseModalType.Message,
                      cardHeader,
                      cardInfoMessage
                    )}}}):_vm._e()],1),_c('div',{staticClass:"h-80"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col pl-0"},[_c('div',{staticClass:"d-flex justify-content-start align-items-center pl-3 my-1"},[(
                          _vm.getsitePerformanceDataPoint(sectionName, id) &&
                          _vm.getsitePerformanceDataPoint(sectionName, id)
                            .length > 0
                        )?_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table"},_vm._l((_vm.getsitePerformanceDataPoint(sectionName, id)),function({
                              id,
                              label,
                              score,
                              rating,
                              bold,
                            }){return _c('tr',{key:id},[(bold)?_c('th',{staticClass:"table-measures"},[_vm._v(" "+_vm._s(label)+" ")]):_c('td',{staticClass:"table-measures"},[_vm._v(_vm._s(label))]),(bold)?_c('th',{staticClass:"table-score"},[_vm._v(" "+_vm._s(score)+" ")]):_c('td',{staticClass:"table-measures"},[_vm._v(_vm._s(score))]),(bold)?_c('th',{staticClass:"table-measures"},[_vm._v(" "+_vm._s(rating)+" ")]):_c('td',{staticClass:"table-measures"},[_vm._v(" "+_vm._s(rating)+" ")])])}),0)]):_vm._e()])])])])])])])}),0)]):_vm._e(),(
        sectionName === 'bestPractices' && _vm.getDataPoint(sectionName) !== null
      )?_c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col pl-0"},[_c('h2',{staticClass:"doe-dark-blue"},[_vm._v(" "+_vm._s(header)),(infoIcon)?_c('base-info-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle.sidebar-Info",modifiers:{"sidebar-Info":true}}],staticClass:"ml-2",on:{"info-click":function($event){return _vm.showModal(_vm.BaseModalType.Message, header, infoMessage)}}}):_vm._e()],1)])]),_c('div',{staticClass:"row"},[_vm._v(" ")]),_c('div',{staticClass:"row"},_vm._l((criteria),function({
            id,
            type,
            message,
            cardHeader,
            cardMessage,
            cardSmallMessage,
            cardInfoIcon,
            cardInfoMessage,
          }){return _c('div',{key:id,staticClass:"col-lg-6 pl-0"},[(type === 'message')?_c('span',{staticClass:"row"},[_c('span',{staticClass:"doe-dark-blue",domProps:{"innerHTML":_vm._s(message)}}),(cardInfoIcon)?_c('base-info-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle.sidebar-Info",modifiers:{"sidebar-Info":true}}],staticClass:"ml-2",on:{"info-click":function($event){return _vm.showModal(_vm.BaseModalType.Message, message, cardInfoMessage)}}}):_vm._e()],1):_c('div',{},[_c('div',{staticClass:"card card-section px-0 pb-0 shadow-none"},[_c('h5',{staticClass:"doe-dark-blue pl-3"},[_vm._v(" "+_vm._s(cardHeader)+" "),(cardInfoIcon)?_c('base-info-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle.sidebar-Info",modifiers:{"sidebar-Info":true}}],staticClass:"ml-2",on:{"info-click":function($event){return _vm.showModal(
                      _vm.BaseModalType.Message,
                      cardHeader,
                      cardInfoMessage
                    )}}}):_vm._e()],1),_c('div',{staticClass:"h-80"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col pl-0"},[_c('div',{staticClass:"d-flex justify-content-start align-items-center pl-3 my-1"},[(cardMessage && cardMessage !== '')?_c('div',[_c('span',{staticClass:"doe-dark-blue",domProps:{"innerHTML":_vm._s(cardMessage)}}),(
                            isNaN(_vm.getSetBestPractiseStar(sectionName, id))
                          )?_c('span',{staticClass:"doe-dark-blue d-block"},[_c('i',[_vm._v(_vm._s(_vm.getSetBestPractiseStar(sectionName, id)))])]):[(_vm.getSetBestPractiseStar(sectionName, id) > 0)?_c('div',[_c('p',[_c('span',{staticClass:"fa fa-star no-rating",class:{
                                  checked:
                                    _vm.getSetBestPractiseStar(sectionName, id) >=
                                    1,
                                }}),_c('span',{staticClass:"fa fa-star no-rating",class:{
                                  checked:
                                    _vm.getSetBestPractiseStar(sectionName, id) >=
                                    2,
                                }}),_c('span',{staticClass:"fa fa-star no-rating",class:{
                                  checked:
                                    _vm.getSetBestPractiseStar(sectionName, id) >=
                                    3,
                                }})])]):_c('div',[(
                                cardSmallMessage && cardSmallMessage !== ''
                              )?_c('div',{staticClass:"row"},[_c('span',{staticClass:"doe-dark-blue",domProps:{"innerHTML":_vm._s(cardSmallMessage)}})]):_vm._e()])]],2):_vm._e()])])])])])])])}),0)]):_vm._e(),(sectionName === 'teacheratthisSite')?_c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col pl-0"},[_c('h2',{staticClass:"doe-dark-blue"},[_vm._v(" "+_vm._s(header)),(infoIcon)?_c('base-info-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle.sidebar-Info",modifiers:{"sidebar-Info":true}}],staticClass:"ml-2",on:{"info-click":function($event){return _vm.showModal(_vm.BaseModalType.Message, header, infoMessage)}}}):_vm._e()],1)])]),_c('div',{staticClass:"row"},[_vm._v(" ")]),_c('div',{staticClass:"row"},_vm._l((criteria),function({
            id,
            type,
            message,
            cardInfoIcon,
            cardInfoMessage,
          }){return _c('div',{key:id,staticClass:"col-lg-6 pl-0"},[(type === 'message')?_c('div',[_c('div',[_c('h6',[_c('span',{staticClass:"doe-dark-blue",domProps:{"innerHTML":_vm._s(message)}}),(cardInfoIcon)?_c('base-info-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle.sidebar-Info",modifiers:{"sidebar-Info":true}}],staticClass:"ml-2",on:{"info-click":function($event){return _vm.showModal(_vm.BaseModalType.Message, message, cardInfoMessage)}}}):_vm._e()],1),_vm._l((_vm.getsitePerformanceDataPoint(
                  sectionName,
                  id
                )),function({ id, label, score }){return _c('div',{key:id,staticClass:"row"},[_c('div',{staticClass:"col pl-0"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-8 pl-0 doe-dark-blue"},[_vm._v(" "+_vm._s(label)+" ")]),_c('div',{staticClass:"col-4 font-weight-bold text-right"},[_vm._v(" "+_vm._s(score !== "" ? score + "%" : "N/A")+" ")])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col pl-0"},[_c('div',{staticClass:"progress"},[_c('div',{class:_vm.selectBarColor(id),style:('width:' + score + '%'),attrs:{"role":"progressbar","aria-valuenow":score,"aria-valuemin":"0","aria-valuemax":"100"}}),_c('div',{staticClass:"progress-bar progressbarLineemptybg",style:('width:' + (100 - score) + '%'),attrs:{"role":"progressbar","aria-valuenow":100 - score,"aria-valuemin":"0","aria-valuemax":"100"}})])])])])])})],2)]):_vm._e()])}),0)]):_vm._e(),(
        (sectionName === 'classroomQuality' &&
          _vm.getDataPoint(sectionName) !== null) ||
        (sectionName === 'classroomQualityMeasured' &&
          _vm.getDataPoint(sectionName) !== null) ||
        (sectionName === 'networkScore' &&
          _vm.getDataPoint(sectionName) !== null) ||
        ((sectionName === 'preKClassroom' ||
          sectionName === 'toddlerClassroom' ||
          sectionName === 'infantClassroom') &&
          _vm.getDataPoint(sectionName)) ||
        (sectionName === 'bestPractices' &&
          _vm.getDataPoint(sectionName) !== null)
      )?_c('hr',{staticClass:"major bg-doe-secondary"}):_vm._e()])}),_c('b-sidebar',{attrs:{"id":"sidebar-Info","title":"","right":"","shadow":"","width":_vm.sidebarWidth,"no-header":true},scopedSlots:_vm._u([{key:"footer",fn:function({ hide }){return [_c('div',{staticClass:"text-light px-3 py-2 text-right bg-doe-light-violet"},[_c('span',{staticClass:"btn span-doe-close-style",on:{"click":hide}},[_c('i',{staticClass:"fas fa-times fa-fw"}),_vm._v(" Close ")])])]}}])},[_c('div',{staticClass:"text-light px-3 py-2 bg-doe-light-violet"},[_c('h5',[_vm._v(_vm._s(this.modalHeaderText))])]),_c('div',{staticClass:"px-3 py-2"},[_c('p',{domProps:{"innerHTML":_vm._s(this.modalMessage)}})])]),_c('base-modal',_vm._b({attrs:{"id":"modal","show-modal":_vm.showItemDetail,"no-close-on-backdrop":"","hide-footer":""},on:{"no-clicked":_vm.onNoClick}},'base-modal',_vm.selectedModalData,false))],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col p-0"},[_c('iframe',{attrs:{"height":"100%","width":"100%","allowfullscreen":"allowfullscreen","src":"https://www.youtube.com/embed/EFFUNOw4XMs"}})])])
}]

export { render, staticRenderFns }