
import { Component, Mixins } from "vue-property-decorator";
import { mapState } from "vuex";
import { BaseModalType } from "@/models/schoolfinder.enums";
import SchoolMixin from "@/mixins/school.mixin";
import {
  ECAcademicPerformance,
  ECPerformance,
  K12School,
} from "@/models/interface";

@Component({
  computed: { ...mapState("app", ["school", "mobileView", "tabletView"]) },
  metaInfo: {
    title: "EC Academic Performance Page",
  },
})
export default class ECAcademicOverAllPerformance extends Mixins(SchoolMixin) {
  BaseModalType = BaseModalType;
  mobileView!: boolean;
  tabletView!: boolean;
  showItemDetail = false;
  modalHeaderText = "";
  modalMessage = "";
  videoSrc = "";
  selectedModalType = BaseModalType.None;
  starRating = 0;
  earlyEdDistrictReport = "";
  earlyEdSchoolReport = "";
  ecAcademicPerformance: Array<ECAcademicPerformance> =
    new Array<ECAcademicPerformance>();
  b3HonorRoll = "";
  excellenceHonorRoll = "";
  topGainsHonorRoll = "";

  constructor() {
    super();
  }

  mounted() {
    const schoolDetails: K12School = this.$store.getters["app/getschool"];
    if (schoolDetails && schoolDetails.ecAcademicPerformance) {
      if (schoolDetails.schoolAbout) {
        this.earlyEdDistrictReport =
          schoolDetails.schoolAbout.earlyEdDistrictReport;
        this.earlyEdSchoolReport =
          schoolDetails.schoolAbout.earlyEdSchoolReport;
      }

      this.ecAcademicPerformance = schoolDetails.ecAcademicPerformance;

      const dm =
        schoolDetails &&
        ((schoolDetails as any).schoolDataPoints as any) &&
        (((schoolDetails as any).schoolDataPoints as any).filter((d: any) => {
          const metricTracked = [
            "B_3_HONOR_ROLL",
            "EXCELLENCE_HONOR_ROLL",
            "TOP_GAINS_HONOR_ROLL",
          ].includes(d.metricId.toUpperCase());

          if (metricTracked) return d;
        }) as Array<any>);

      const b3Metric = dm.find(
        (e: any) => e.metricId.toUpperCase() == "B_3_HONOR_ROLL"
      );
      this.b3HonorRoll =
        b3Metric && b3Metric.schoolResult
          ? (b3Metric.schoolResult as string)
          : "";

      const excellenceMetric = dm.find(
        (e: any) => e.metricId.toUpperCase() == "EXCELLENCE_HONOR_ROLL"
      );
      this.excellenceHonorRoll =
        excellenceMetric && excellenceMetric.schoolResult
          ? (excellenceMetric.schoolResult as string)
          : "";

      const topGainMetric = dm.find(
        (e: any) => e.metricId.toUpperCase() == "TOP_GAINS_HONOR_ROLL"
      );
      this.topGainsHonorRoll =
        topGainMetric && topGainMetric.schoolResult
          ? (topGainMetric.schoolResult as string)
          : "";
    }
  }

  showModal(modalType: BaseModalType, header: string, content: string) {
    this.modalHeaderText = header;
    this.selectedModalType = modalType;

    switch (modalType) {
      case BaseModalType.Message:
        this.modalMessage = content;
        break;
      case BaseModalType.Video:
        this.videoSrc = content;
        break;
      default:
        break;
    }

    //this.showItemDetail = true;
  }

  onNoClick() {
    this.showItemDetail = false;
    this.selectedModalType = BaseModalType.None;
    this.modalHeaderText = "";
    this.modalMessage = "";
    this.videoSrc = "";
  }

  get selectedModalData() {
    return this.modalData.find(
      (item) => item.modalType === this.selectedModalType
    );
  }

  get sidebarWidth(): string {
    let result = "";
    if (this.mobileView) {
      result = "100%";
    } else if (this.tabletView) result = "40%";
    return result;
  }

  get modalData() {
    return [
      {
        modalType: BaseModalType.Video,
        headerText: "School Performance Scores",
        videoSrc: this.videoSrc,
      },
      {
        modalType: BaseModalType.Message,
        headerText: this.modalHeaderText,
        modalMessage: this.modalMessage,
      },
      {
        modalType: BaseModalType.None,
        headerText: "",
        modalMessage: "",
      },
    ];
  }

  getRating(section: string): any {
    const data: ECPerformance = this.getDataPointPerformanceFirst(section);
    if (data && data.rating) return data.rating;

    return "";
  }

  getScore(section: string): any {
    const data: ECPerformance = this.getDataPointPerformanceFirst(section);
    if (data && data.score) return data.score;

    return "";
  }

  getScoreYear(section: string): any {
    const data: ECPerformance = this.getDataPointPerformanceFirst(section);
    if (data && data.scoreYear) return data.scoreYear;

    return "";
  }

  getDataPointPerformanceFirst(section: string): any {
    if (!this.ecAcademicPerformance) return null;

    const data = this.ecAcademicPerformance.find(
      (item) => item.sectionName === section
    );

    return data?.performances && data?.performances.length > 0
      ? data?.performances[0]
      : null;
  }

  getDataPointPerformances(section: string): any {
    if (!this.ecAcademicPerformance) return null;

    const data = this.ecAcademicPerformance.find(
      (item) => item.sectionName === section
    );
    return data?.performances;
  }

  getDataPoint(section: string): any {
    if (!this.ecAcademicPerformance) return null;

    const data = this.ecAcademicPerformance.find(
      (item) => item.sectionName === section
    );
    return data;
  }

  getsitePerformanceDataPoint(section: string, id: number): any {
    if (!this.ecAcademicPerformance) return null;

    const data = this.ecAcademicPerformance.find(
      (item) => item.sectionName === section
    );

    if (data?.sitePerformances) {
      return data?.sitePerformances?.find((item) => item.id === id)?.data;
    }

    return null;
  }

  selectBarColor(id: number): string {
    let barColorClass = "";
    switch (id) {
      case 0:
        barColorClass = "progress-bar barColorbcbfda";
        break;
      case 1:
        barColorClass = "progress-bar barColor9ab185";
        break;
      case 2:
        barColorClass = "progress-bar barColorCCCCCC";
        break;
      case 3:
        barColorClass = "progress-bar barColorBED0DA";
        break;
      case 4:
        barColorClass = "progress-bar barColorbcbfda";
        break;
      case 5:
        barColorClass = "progress-bar barColor9ab185 ";
        break;
    }
    return barColorClass;
  }

  getSetBestPractiseStar(
    section: string,
    id: number
  ): number | string | undefined {
    let result;
    if (!this.ecAcademicPerformance) return result;

    const data = this.ecAcademicPerformance.find(
      (item) => item.sectionName === section
    );

    if (data && data.performances) {
      const performance = data?.performances?.find((item) => item.id === id);
      if (performance && performance.rating) {
        if (
          performance.rating.includes("Stars") ||
          performance.rating.includes("Star")
        )
          result = parseInt(performance.rating.replace("Stars", ""), 0);
        else result = performance.rating;
      }
    }
    return result;
  }

  get sidebarMessage() {
    const result = {
      excellanceHonorRollMessage:
        "This honor roll recognizes all Excellent sites, which have no unsatisfactory scores in any domain.",
      excellanceHonorRollHeader: "Excellence Honor Roll",

      topGainsHonorRollMessage:
        "Top Gains schools are those demonstrating exceptional student progress: earning an “A” for student progress, ensuring that students meet or exceed their learning goals each year. Top Gains schools also must not be persistently struggling with specific groups of students or student behavior.",
      topGainsHonorRollHeader: "Top Gains Honor Roll",
    };
    return result;
  }
  get componentData() {
    return [
      {
        id: 1,
        header: "What is this site’s rating for classroom quality?",
        infoIcon: true,
        infoMessage:
          "The overall site measure of classroom quality reflects all of the classrooms observations done throughout the year. The scale has five levels: Unsatisfactory, Approaching Proficient, Proficient, High Proficient and Excellent.",
        sectionName: "classroomQuality",
        downloadLink: this.earlyEdSchoolReport,
        downloadLabel: "Download PDF Report Card",
      },
      {
        id: 2,
        header: "How is classroom quality  measured?",
        sectionName: "classroomQualityMeasured",
        infoIcon: false,
        infoMessage: "",
        downloadLink:
          "https://www.louisianabelieves.com/data/files/ECE/2019/010000.pdf",
        downloadLabel: "View this network’s performance profile",
        criteria: [
          { id: 201, key: "Excellent", value: "6.00–7.00" },
          { id: 202, key: "High Proficient", value: "5.25–5.99" },
          { id: 203, key: "Proficient", value: "4.50–5.24" },
          { id: 204, key: "Approaching Proficient", value: "3.00–4.49" },
          { id: 205, key: "Unsatisfactory", value: "1.00–2.99" },
        ],
        bodyMessage:
          "These are scores based on classroom observations of teacher-child interactions. Classrooms with high-quality teacher-child interactions are supportive, organized, and promote children's learning and development.",
        videoLabel: "Watch a video on how this is measured",
        videoSrc: "https://www.youtube.com/embed/EFFUNOw4XMs",
      },
      {
        id: 3,
        header: "How does this site’s score compare to its network’s score?",
        infoIcon: false,
        infoMessage: "",
        sectionName: "networkScore",
        downloadLink:
          "https://www.louisianabelieves.com/data/files/ECE/2019/017000.pdf",
        downloadLabel: "View this network’s performance profile",
      },
      {
        id: 4,
        header:
          "How is this site performing in measures for Pre-K classrooms (3-5 years)?",
        infoIcon: false,
        infoMessage: "",
        sectionName: "preKClassroom",
        bodyMessage: ``,
        downloadLink: "",
        downloadLabel: "",
        criteria: [
          {
            id: 1,
            type: "message",
            message: `Louisiana classrooms are observed using CLASS®, a nationally recognized tool. These in-depth measures show how well classrooms support children’s growth and development.`,
            
          },
          {
            id: 2,
            type: "card",
            cardHeader: "Emotional Support",
            cardInfoIcon: true,
            cardInfoMessage: `Emotional Support measures the responsiveness and sensitivity of the classroom to children’s emotions, which helps develop their ability to interact with others.`,
          },
          {
            id: 3,
            type: "card",
            cardHeader: "Classroom Organization",
            cardInfoIcon: true,
            cardInfoMessage: `Classroom Organization measures how a classroom is organized to manage children’s attention, time, and behavior.`,
          },
          {
            id: 4,
            type: "card",
            cardHeader: "Instructional Support",
            cardInfoIcon: true,
            cardInfoMessage: `Instructional Support measures how classroom interactions and experiences help children develop language and learn new concepts.`,
          },
        ],
      },
      {
        id: 5,
        header:
          "How is this site performing in measures for Toddler classrooms (1-2 years)?",
        infoIcon: false,
        infoMessage: "",
        sectionName: "toddlerClassroom",
        bodyMessage: ``,
        downloadLink: "",
        downloadLabel: "",
        criteria: [
          {
            id: 1,
            type: "message",
            message: `Louisiana classrooms are observed using CLASS®, a nationally recognized tool. These in-depth measures show how well classrooms support children’s growth and development.`,
          },
          {
            id: 2,
            type: "card",
            cardHeader: "Emotional & Behavioral Support",
            cardInfoIcon: true,
            cardInfoMessage: `Emotional and Behavioral Support measures the responsiveness and sensitivity of the classroom to children’s emotions, which helps develop their ability to interact with others, and how a classroom is organized to manage children’s attention, time, and behavior.`,
          },
          {
            id: 3,
            type: "card",
            cardHeader: "Engaged Support for Learning",
            cardInfoIcon: true,
            cardInfoMessage: `Engaged Support for Learning measures how classroom interactions and experiences help children develop language and learn new concepts.`,
          },
        ],
      },
      {
        id: 6,
        header:
          "How is this site performing in measures for Infant classrooms (birth-18 months)?",
        infoIcon: false,
        infoMessage: "",
        sectionName: "infantClassroom",
        bodyMessage: ``,
        downloadLink: "",
        downloadLabel: "",
        criteria: [
          {
            id: 1,
            type: "message",
            message: `Louisiana classrooms are observed using CLASS®, a nationally recognized tool. These in-depth measures show how well classrooms support children’s growth and development.`,
          },
          {
            id: 2,
            type: "card",
            cardHeader: "Responsive Caregiving",
            cardInfoIcon: false,
            cardInfoMessage: ``,
          },
        ],
      },
      {
        id: 7,
        header: "How is this site using best practices?",
        infoIcon: false,
        infoMessage: "",
        sectionName: "bestPractices",
        bodyMessage: ``,
        downloadLink: "",
        downloadLabel: "",
        criteria: [
          {
            id: 1,
            type: "message",
            message: `Stars help parents understand the practices in their children’s classrooms. This is self-reported informatIon that is not included in the site’s rating.`,
          },
          {
            id: 2,
            type: "card",
            cardHeader: "Assessment",
            cardMessage: "Are teachers measuring progress?",
            cardInfoIcon: true,
            cardInfoMessage: `Assessment shows if teachers are evaluating children’s growth and development.`,
          },
          {
            id: 3,
            type: "card",
            cardHeader: "Children per Teacher",
            cardMessage: "Will my child receive close attention?",
            cardInfoIcon: true,
            cardInfoMessage: `Children per teacher shows the quality of the teacher:child ratios for the site. The smaller the ratio, the more individual attention children can receive.`,
          },
          {
            id: 4,
            type: "card",
            cardHeader: "Curriculum Quality: Pre-K",
            cardMessage: `What is the quality of the curriculum in Pre-K classrooms?`,
            cardSmallMessage: `<small>No curriculum used or curriculum used has not been rated by the state.</small>`,
            cardInfoIcon: true,
            cardInfoMessage: `Curriculum Quality shows how the site uses activities and materials to promote children’s development and learning. This measures how the site’s curriculum compares with the state’s indicators of quality. Using high-quality activities promotes kindergarten readiness.`,
          },
          {
            id: 5,
            type: "card",
            cardHeader: "Curriculum Quality: Infant/Toddler",
            cardMessage: `What is the quality of the curriculum in infant/toddler classrooms?`,
            cardSmallMessage: `<small>No curriculum used or curriculum used has not been rated by the state.</small>`,
            cardInfoIcon: true,
            cardInfoMessage: `Curriculum Quality shows how the site uses activities and materials to promote children’s development and learning. This measures how the site’s curriculum compares with the state’s indicators of quality. Using high-quality activities promotes kindergarten readiness.`,
          },
        ],
      },
      {
        id: 8,
        header: "How prepared are the teachers at this site?",
        infoIcon: false,
        infoMessage: "",
        sectionName: "teacheratthisSite",
        bodyMessage: ``,
        downloadLink: "",
        downloadLabel: "",
        criteria: [
          {
            id: 1,
            type: "message",
            message: `What is the highest degree earned by teachers at this site?`,
            cardInfoIcon: true,
            cardInfoMessage: `Teacher degrees shows the highest level of education teachers have completed. Louisiana teachers need excellent training and ongoing professional development to provide children with experiences they need to develop. Degree requirements vary by program type and site director’s preferences.`,
          },
          {
            id: 2,
            type: "message",
            message: `How many teachers at this site are certified by the state of Louisiana?`,
            cardInfoIcon: true,
            cardInfoMessage: `The percent of certified teachers shows how many teachers have an approved teacher certification. Teaching certificate requirements vary by type of program.`,
          },
        ],
      },
    ];
  }
}
